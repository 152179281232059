import { RouterProvider } from 'react-router-dom';
import route from './route/router';


function App() {
  return (
    <>
      <RouterProvider router={route} >
      </RouterProvider>
      {/* <BrowserRouter>
        <RentalLayout />
      </BrowserRouter> */}
    </>

  );
}

export default App;
