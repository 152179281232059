import RentalTabBar from '../tabBar'
import './css/layout.css'
import { Outlet } from 'react-router-dom'

const RentalLayout = () => {

  return (
    <>
      <div className='layout'>
        <div className='body'>
          <Outlet></Outlet>
        </div>
        <div className='bottom'>
          <RentalTabBar />
        </div>
      </div>

    </>

  )
}

export default RentalLayout