import { Button, Card, Space } from 'antd-mobile'
import { useNavigate, useParams } from 'react-router'
import RentalBack from '../../../components/back'
import './css/detail.css'
import { AntOutline } from 'antd-mobile-icons'
import ProductBaseCard from '../../../components/product/base'

const RentalOrderDetail = () => {
  const params = useParams()
  const id = params.id
  return (
    <div className='order-detail-page'>
      <div style={{ height: '6vh' }}>
        <OrderBack id={id} />
      </div>

      {/* <ReceiverAddress id={id} /> */}
      <div className='order-detail-body'>
        <div className='order-detail-card'>
          <Card title={
            <div style={{ fontWeight: 'normal' }}>
              <AntOutline style={{ marginRight: '4px', color: '#1677ff' }} />
              订单信息
            </div>
          }>
          </Card>
        </div>

        <OrderDetail id={id} />
      </div>
      <OrderBottom id={id} />
    </div>
  )
}

/**
 * 订单页返回导航栏
 * @param id 商品id 
 * @returns 
 */
function OrderBack({ id }) {
  const navigate = useNavigate()
  const right = (
    <div style={{ fontSize: 24 }}>
      <Space style={{ '--gap': '16px', width: '100vw' }}>
        {/* <SearchOutline />
        <MoreOutline /> */}
      </Space>
    </div>
  )
  const handBack = () => {
    navigate(-1)
  }
  return (
    <RentalBack title={'订单信息'} right={right} handBack={handBack} />
  )
}
/**
 * 用户收货地址
 * @param id 订单id 
 * @returns 
 */
function ReceiverAddress({ id }) {
  const receiverAddress = '阿克苏砥砺奋进阿卡丽飞机啦会计法啦开发机阿拉啥电极法考拉电极法拉萨的阿斯顿 发发大水发'
  const name = '张三'
  const mobile = '13123456789'
  const handClick = () => {
    console.log('变更地址...' + id)
  }
  return (
    <div className='order-detail-card'>

      <Card onClick={handClick} title={
        <div style={{ fontWeight: 'normal' }}>
          <AntOutline style={{ marginRight: '4px', color: '#1677ff' }} />
          收货地址
        </div>
      }>
        <div className='receiver-address'>
          详细地址：{receiverAddress.length > 35 ? receiverAddress.substring(0, 35) + '...' : receiverAddress}
        </div>
        <div className='receiver-username'>
          <div>姓名：{name}</div><div>手机号：{mobile}</div>
        </div>
      </Card>

    </div>
  )
}

/**
 * 订单体整体商品信息
 * @param {*} param0 
 * @returns 
 */
function OrderDetail({ id }) {

  return (
    <div className='order-detail-card'>
      <OrderDetailCard />
      <OrderDetailCard />
      <OrderDetailCard />
    </div>
  )
}

/**
 * 订单中商品卡片
 * @returns 
 */
function OrderDetailCard() {
  return (
    <div>
      <Card style={{ marginTop: '8px' }}>
        <div>
          <ProductBaseCard name={'商品名称'} price={123} categoryName={'商品分类/黑色'} />
        </div>

        <div className='order-detail-zu'>
          <div>
            起租：5天
          </div>
          <div>
            ￥ 1.23/天
          </div>
        </div>

        <div>
          <div style={{ display: 'flex', marginTop: '8px', fontSize: '18px' }}>
            <div style={{ alignSelf: 'flex-start', width: '50vw' }}>
              订单编号：
            </div>
            <div style={{ alignSelf: 'flex-end', width: '50vw', textAlign: 'right' }}>
              12345623162
            </div>
          </div>

          <div style={{ display: 'flex', marginTop: '8px', fontSize: '18px' }}>
            <div style={{ alignSelf: 'flex-start', width: '50vw' }}>
              创建时间：
            </div>
            <div style={{ alignSelf: 'flex-end', width: '50vw', textAlign: 'right' }}>
              2024-3-23 14:17:57
            </div>
          </div>

          <div style={{ display: 'flex', marginTop: '8px', fontSize: '18px' }}>
            <div style={{ alignSelf: 'flex-start', width: '50vw' }}>
              实付价格：
            </div>
            <div style={{ alignSelf: 'flex-end', width: '50vw', textAlign: 'right' }}>
              ￥ 6.15
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}


function OrderBottom({ id }) {
  const navigate = useNavigate()
  const handClick = () => {
    navigate("/order/result/" + id)
  }
  return (
    <div className='order-detail-bottom'>
      <div>共1件</div>
      <div>合计：￥ 6.15</div>
      <div><Button color='warning' onClick={handClick}>提交订单</Button></div>
    </div>
  )
}
export default RentalOrderDetail