import { Button, Footer, Grid, Image, ImageViewer, List, Popup, Space, Swiper } from 'antd-mobile'
import { useNavigate, useParams } from 'react-router-dom'
import RentalBack from '../../../components/back'
import { ChatCheckOutline, HandPayCircleOutline, MoreOutline, RightOutline, SearchOutline, ShopbagOutline } from 'antd-mobile-icons'
import { useState } from 'react'
import ProductCommentList from '../comment'
import './css/layout.css'
import { BookOutlined, ShoppingCartOutlined } from '@ant-design/icons'

const ProductDetail = () => {

  const params = useParams()
  const id = params.id
  const title = '商品标题'
  return (
    <div className='layout'>
      <ProductBack id={id} />
      <ProductBody>
        <ProductSummary />
        <ProductPrice price={10} />
        <ProductName productName={title} />
        <ProductParams id={id} />
        <ProductComment id={id} />
        <ProductDesc productDescImages={[]} />
      </ProductBody>
      <ProductDownNavBar id={id} price={100.56} dayPrice={12.3} dailyRent={5} onlyRent={true} onlySale={true} />
    </div>
  )
}

/**
 * 商品页面体
 * @param children 组件 
 * @returns 
 */
function ProductBody({ children }) {
  return (
    <div className='body'>
      {children}
    </div>
  )
}

/**
 * 商品概要图片组件
 * @param summaryImages 概要图片
 * @returns 
 */
function ProductSummary({ summaryImages }) {
  if (summaryImages === undefined || summaryImages === null) {
    summaryImages = [];
    for (let i = 0; i < 4; i++) {
      summaryImages.push('/images/示例图片.png')
    }
  }
  const handClick = (index) => {
    ImageViewer.Multi.show({
      defaultIndex: index,
      images: summaryImages
    })
  }
  const items = summaryImages.map((item, index) => (
    <Swiper.Item key={index}>
      <Image src={item} style={{ width: '100vw', height: '40vh' }} fit='cover' onClick={() => handClick(index)} />
    </Swiper.Item>
  ))

  return (
    <div style={{ textAlign: 'center' }}>
      <Swiper loop autoplay>
        {items}
      </Swiper>
    </div>
  )
}

/**
 * 商品页返回导航栏
 * @param id 商品id 
 * @returns 
 */
function ProductBack({ id }) {
  const navigate = useNavigate()
  const right = (
    <div style={{ fontSize: 24 }}>
      <Space style={{ '--gap': '16px' }}>
        <SearchOutline />
        <MoreOutline />
      </Space>
    </div>
  )
  const handBack = () => {
    navigate(-1)
  }
  return (
    <RentalBack title={"商品信息"} right={right} handBack={handBack} />
  )

}

/**
 * 商家价格与出售数量组件
 * @param price 商品价格
 * @param saleNumber 商品售卖数量
 * @returns 
 */
function ProductPrice({ price, saleNumber }) {
  // const saleText = <span>已售</span>
  // if (saleNumber % 10 > 0) {
  //   saleText = <span>{saleText}10+</span>
  // } else (
  //   saleText = <span>{saleText}{saleNumber}</span>
  // )
  return (
    <Grid columns={2} gap={10}>
      <Grid.Item>
        ¥:{price}
      </Grid.Item>
      <Grid.Item>
        {/* {saleText} */}
      </Grid.Item>
    </Grid>
  )
}

/**
 * 商品名称组件
 * @param productName 商品名称
 * @returns 
 */
function ProductName({ productName }) {

  return (
    <div style={{ marginTop: '15px', whiteSpace: 'normal', fontSize: '16px' }}>
      <span>我是一个很长很长很长很长很长很长很长很长很长很长很长很长的商品名字...</span>
    </div>
  )
}
/**
 * 商品规格参数介绍
 * @param id 商品id 
 * @returns 
 */
function ProductParams({ id }) {
  const [popup, setPopup] = useState(false)
  const mockContent = (
    <div>
      <List header='基础用法'>
        <List.Item>1</List.Item>
        <List.Item>2</List.Item>
        <List.Item>3</List.Item>
      </List>
    </div>
  )
  const handClick = () => {
    setPopup(true)
  }
  return (
    <div style={{ marginTop: '10px' }}>
      <List>
        <List.Item prefix={<ShopbagOutline />} onClick={handClick}>
          产品参数
        </List.Item>
      </List>
      <Popup
        visible={popup}
        onMaskClick={() => {
          setPopup(false)
        }}
        onClose={() => {
          setPopup(false)
        }}
        bodyStyle={{ height: '40vh' }}
      >
        {mockContent}
      </Popup>
    </div>
  )
}

/**
 * 商品评价
 * @param id 商品id 
 */
function ProductComment({ id }) {
  const [visible, setVisible] = useState(false)
  const handClick = () => {
    setVisible(true)
  }
  const handBack = () => {
    setVisible(false)
  }
  const mockContent = (
    <div>
      <RentalBack handBack={handBack} />
      <ProductCommentList />
    </div>
  )
  return (
    <div style={{ marginTop: '20px', fontSize: '17px' }} onClick={handClick}>
      <Grid columns={2} gap={4}>
        <Grid.Item>
          <ChatCheckOutline /> 产品评价(99+)
        </Grid.Item>
        <Grid.Item>
          <div style={{ textAlign: 'right', color: 'gray' }}>
            全部<RightOutline />
          </div>
        </Grid.Item>
        <Grid.Item>
          <div>头像：昵称 - <span>购买分类</span></div>
          <div>评论内容</div>
        </Grid.Item>
      </Grid>

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
        position='right'
        bodyStyle={{ width: '100vw' }}
      >
        {mockContent}
      </Popup>
    </div>
  )
}

/**
 * 商品详情图片
 * @param productDescImages 商品详情图片
 * @returns 
 */
function ProductDesc({ productDescImages }) {
  if (productDescImages === null || productDescImages === undefined) {
    productDescImages = []
  }
  const url = 'https://t7.baidu.com/it/u=2529476510,3041785782&fm=193&f=GIF';
  return (
    <div>
      <Footer
        label={
          <div>
            <HandPayCircleOutline /> 商品详情
          </div>
        }
      ></Footer>
      <div style={{ marginTop: '5px' }}>
        {
          productDescImages.map((item, index) => (
            <Image src={item} key={index} style={{ width: '100vw', height: '20vh' }} />
          ))
        }
      </div>


      <Image src={url} style={{ height: '40vh' }} />
      <Image src={url} style={{ height: '40vh' }} />
    </div>
  )
}

/**
 * 商品底部操作栏组件
 * @param price 商品价格
 * @param dayPrice 商品日租价格
 * @param dailyRent 起租天数
 * @param onlyRent 是否出租
 * @param onlySale 是否售卖 
 * @returns 
 */
function ProductDownNavBar({ id, price, dayPrice, dailyRent, onlyRent, onlySale }) {
  const [visible, setVisible] = useState(false)
  const [handType, setHandType] = useState(false)
  const handClick = (type) => {
    setVisible(true)
    setHandType(type)
  }

  return (
    <div className='bottom'>
      <Grid columns={3}>
        <Grid.Item>
          <div style={{ display: 'inline-block', fontSize: '12px' }}>
            ￥：{price}<br />
            ￥：{dayPrice}/日
          </div>
          <div style={{ display: 'inline-block', width: '17vw', textAlign: 'right', fontSize: '20px' }}>
            <span style={{ color: 'crimson' }}>{dailyRent}</span>天
          </div>
        </Grid.Item>
        <Grid.Item span={2} style={{ paddingRight: '10px' }}>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Button color='warning' size='small'>
              <Space>
                <ShoppingCartOutlined />
                <span>购物车</span>
              </Space>
            </Button>
            {onlySale && <Button style={{ "--background-color": 'red' }} size='small' onClick={() => handClick(1)}>
              <Space>
                <HandPayCircleOutline />
                <span>购买</span>
              </Space>
            </Button>}
            {onlyRent && <Button color='primary' size='small' onClick={() => handClick(0)}>
              <Space>
                <BookOutlined />
                <span>租</span>
              </Space>
            </Button>}
          </div>
        </Grid.Item>
      </Grid>

      <Popup
        visible={visible}
        onMaskClick={() => {
          console.log('mask')
          setVisible(false)
        }}
        onClose={() => {
          console.log('close')
          setVisible(false)
        }}
        position='bottom'
        bodyStyle={{ height: '60vh' }}
      >
        <ProductSkuParams pMethod={handType} id={id} price={price} />
      </Popup>
    </div>
  )
}

/**
 * 
 * @param id 商品id
 * @param pMethod 购买方式,0:出租，1:购买
 * @returns 
 */
function ProductSkuParams({ id, pMethod, image, price, endPrice }) {
  const navigate = useNavigate()
  const width = 60;
  const handCategoryClick = () => {
    console.log("分类")
  }
  const handCommitClick = () => {
    console.log("提交订单请求")
    const id = "or123456"
    navigate("/order/detail/" + id)
  }
  return (
    <div className='product-buy'>
      <div className='product-price-area'>
        <div className='product-tip'>
          我要：{pMethod === 0 ? '租' : '买'}
        </div>
        <div className='sku-price'>
          <div>
            <Image src={image} width={60} height={60} />
          </div>
          <div>
            商品名称
          </div>
          <div>
            {price} - {endPrice}
          </div>
        </div>
      </div>
      <div className='product-category-area'>
        <div style={{ marginTop: '8px' }}>颜色分类</div>
        <div className='product-category'>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
        </div>
        <div className='product-category'>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
        </div>
        <div className='product-category'>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
        </div>
        <div className='product-category'>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
          <div onClick={handCategoryClick}><Image src={image} width={width} height={width} /> 商品分类</div>
        </div>
      </div>
      <div className='product-operate-area'>
        <Button color='primary' shape='rounded' onClick={handCommitClick}>{pMethod === 0 ? '租' : '买'}</Button>

      </div>

    </div>

  )
}
export default ProductDetail