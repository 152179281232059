
import { Image } from 'antd-mobile'
import './css/baseCard.css'

/**
 * 商品横向信息展示
 * @param image 商品图片
 * @param name 商品名称
 * @param categoryName 商品分类名称
 * @param price 商品价格
 * @returns 
 */
const ProductBaseCard = ({ image, name, categoryName, price }) => {

  return (
    <div className='product-base-card'>
      <div className='product-base-card-image'>
        <Image src={image} width={80} height={80} style={{ borderRadius: 8 }} />
      </div>
      <div className='product-base-card-category'>
        <div style={{ fontSize: '18px' }}>
          {name}
        </div>
        <div style={{ color: '#8d7575', fontFamily: 'fantasy', fontSize: '16px' }}>
          {categoryName}
        </div>
      </div>
      <div>
        <span style={{ fontSize: '12px' }}>￥</span><span style={{ fontSize: '18px' }}>{price}</span>
      </div>
    </div>
  )
}

export default ProductBaseCard