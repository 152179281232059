import { Result, Space } from 'antd-mobile'
import './css/result.css'
import { useNavigate, useParams } from 'react-router-dom'
import RentalBack from '../../../components/back'

const RentalOrderResult = () => {
  const param = useParams()
  const id = param.id
  const description = "订单" + id + "下单成功"
  return (
    <div className='order-result-page'>
      <div className='order-result-body'>
        <OrderBack id={id} />
        <Result status='success' title="下单成功" description={description} />
      </div>
    </div>
  )
}

/**
 * 订单页返回导航栏
 * @param id 商品id 
 * @returns 
 */
function OrderBack() {
  const navigate = useNavigate()
  const right = (
    <div style={{ fontSize: 24, width: '100vw' }}>
      <Space style={{ '--gap': '16px', width: '100vw' }}>
        {/* <SearchOutline />
        <MoreOutline /> */}
      </Space>
    </div>
  )
  const handBack = () => {
    navigate("/order/list")
  }
  return (
    <RentalBack title={'订单结果'} right={right} handBack={handBack} />
  )
}

export default RentalOrderResult