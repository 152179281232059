import { useNavigate } from 'react-router-dom';
import './css/card.css'
import { Image, Space, Tag } from 'antd-mobile';


const ProductListCard = ({ src, id, labels, name, price, dayPrice }) => {

  const navigate = useNavigate()
  if (labels === undefined || labels === null) {
    labels = [];
  }
  const handClick = () => {
    console.log(id)
    navigate("/product/detail/" + id)
  }

  return (
    <div className='card' onClick={handClick}>
      <Space wrap>
        <div>
          <Image
            src={src}
            width={160}
            height={130}
            fit='contain'
            style={{ borderRadius: 20 }}
          />
        </div>
        <div className='field'>
          {
            labels.map(item => {
              return (
                <Tag key={item.id} className='tag' color={item.color}>{item.name}</Tag>
              )
            })
          }
          {/* <Tag className='tag' round color='#2db7f5'>
            降价处理
          </Tag>
          <Tag className='tag' round color='#2db7f5'>
            降价处理
          </Tag>
          <Tag className='tag' round color='#2db7f5'>
            降价处理
          </Tag>
          <Tag className='tag' round color='#2db7f5'>
            降价处理
          </Tag>
          <Tag className='tag' round color='#2db7f5'>
            降价处理
          </Tag>
          <Tag className='tag' round color='#2db7f5'>
            降价处理
          </Tag>
          <Tag className='tag' round color='#2db7f5'>
            降价处理
          </Tag>
          <Tag className='tag' round color='#2db7f5'>
            降价处理
          </Tag> */}
        </div>
        <div className='field'>
          {name}
        </div>
        <div className='price'>
          ¥:{price}
        </div>
        <div className='price'>
          {dayPrice != null && dayPrice + '/日'}
        </div>
      </Space>
    </div>
  )
}

export default ProductListCard;