import { useParams } from 'react-router'


const RentalOrder = () => {
  const params = useParams()
  const id = params.id
  return (
    <div>
      订单:{id}
    </div>
  )
}

export default RentalOrder