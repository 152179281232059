import { createHashRouter } from 'react-router-dom';
import RentalHome from '../pages/home';
import RentalLayout from '../pages/layout';
import RentalMe from '../pages/me';
import RentalShoppingCart from '../pages/cart';
import RentalOrder from '../pages/order';
import ProductDetail from '../pages/product/detail';
import A from '../pages/test/A';
import RentalOrderDetail from '../pages/order/detail';
import RentalOrderResult from '../pages/order/result';


const route = createHashRouter([
  {
    path: '/',
    element: <RentalLayout />,
    children: [
      { path: "/", element: <RentalHome />, index: true },
      { path: "/shopping/cart", element: <RentalShoppingCart /> },
      { path: "/order/list", element: <RentalOrder /> },
      { path: "/me", element: <RentalMe /> },
    ]
  },
  {
    path: "/product/detail/:id",
    element: <ProductDetail />
  },
  {
    path: "/order/detail/:id",
    element: <RentalOrderDetail />
  },
  {
    path: "/order/result/:id",
    element: <RentalOrderResult />
  },
  {
    path: "/test",
    element: <A />
  }
  // },
  // {
  //   path: "/page",
  //   element: <RentalLayout />,
  //   children: [
  //     { path: "/page/home", element: <RentalHome />, index: true },
  //     { path: "/page/shopping/cart", element: <RentalShoppingCart /> },
  //     { path: "/page/order", element: <RentalOrder /> },
  //     { path: "/page/me", element: <RentalMe /> },
  //   ]
  // }
])

export default route