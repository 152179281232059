
import './a.css'

const A = () => {

  return (
    <div className='container'>
      <div className='items1'>1</div>
      <div className='items3'>3</div>
    </div>
  )
}

export default A