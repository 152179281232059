import { Toast } from 'antd-mobile';
import axios from 'axios';

//const baseURL = "http://127.0.0.1:8081"
const baseURL = "https://mall.my-collect.top/api";
const request = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  timeout: 300000
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  if (response.data.code != 200) {
    Toast.show({
      icon:'fail',
      content: response.data.data.message
    })
    //message.error(response.data.data.message)
  }
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default request;