

const RentalShoppingCart = () => {

  return (
    <div>
      购物车
    </div>
  )
}

export default RentalShoppingCart;