import { TabBar } from 'antd-mobile'
import { AppOutline, TruckOutline, UnorderedListOutline, UserOutline } from 'antd-mobile-icons'
import { useLocation, useNavigate } from 'react-router-dom'


const RentalTabBar = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const setRouteActive = (value) => {
    navigate(value)
  }

  const tabs = [
    {
      key: '/',
      title: '首页',
      icon: <AppOutline />,
    },
    {
      key: '/shopping/cart',
      title: '购物车',
      icon: <TruckOutline />,
    },
    {
      key: '/order/list',
      title: '订单',
      icon: <UnorderedListOutline />,
    },
    {
      key: '/me',
      title: '我的',
      icon: <UserOutline />,
    },
  ]

  return (
    <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
      {tabs.map(item => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  )
}

export default RentalTabBar;