
import { Input, Tooltip } from 'antd';
import './css/home.css'
import { Badge, Button, Grid, Image, InfiniteScroll, Space, Swiper, Toast } from 'antd-mobile';
import { BellOutline, SearchOutline } from 'antd-mobile-icons';
import { useEffect, useState } from 'react';
import ProductListCard from '../../components/product/card';
import request from '../../utils/request';
import res from 'antd-mobile-icons/es/AaOutline';
import { useNavigate } from 'react-router';
import { getBanners } from '../../apis/ad';



const RentalHome = () => {
  localStorage.setItem("shopId", 1)
  return (
    <div>
      <Top />
      <HomeSearch />
      <RentalBanner />
      <KingKong />
      <Product />
    </div>
  )
}

function Top() {

  return (
    <div style={{ height: '30px', marginBottom: '10px' }}>
      <Grid columns={2} gap={3} >
        <Grid.Item>
          致知屋租聘系统
        </Grid.Item>
        <Grid.Item>
          <div style={{ textAlign: 'right', marginTop: '7px', paddingRight: '17px' }}>
            <Space style={{ '--gap': '24px' }}>
              <Badge content='99'>
                <div className="notification" >
                  <BellOutline style={{ width: '24px', height: '24px' }} />
                </div>
              </Badge>
            </Space>
          </div>
        </Grid.Item>
      </Grid>
    </div>

  )
}

function RentalBanner() {

  const navigate = useNavigate()
  const shopId = localStorage.getItem("shopId");
  const [banners, setBanners] = useState([])
  useEffect(() => {
    getBanners(shopId, setBanners)
  }, []);
  const handClick = (banner) => {
    if (banner.connect === 0) {
      navigate(banner.internalUrl)
    } else {
      window.location.href = banner.externalUrl
    }
  }
  const items = banners.map((banner) => (
    <Swiper.Item key={banner.id}>
      <Image src={banner.img} onClick={() => { handClick(banner) }} />
    </Swiper.Item>
  ))

  return (
    <div>
      <Swiper
        loop
        autoplay
      // onIndexChange={i => {
      //   console.log(i, 'onIndexChange1')
      // }}
      >
        {items}
      </Swiper>
    </div>
  )
}

function HomeSearch() {

  const [value, setValue] = useState('')
  const handClick = () => {
    console.log("输入的内容：", value)
  }
  const handChange = (v) => {
    console.log(v.target.value)
    setValue(v.target.value)
  }
  return (
    <Input
      style={{ marginTop: "5px", marginBottom: '5px' }}
      placeholder="请输入商品名称"
      onChange={handChange}
      suffix={
        <Tooltip title="Extra information">
          <SearchOutline onClick={handClick} />
        </Tooltip>
      }
    />
  )
}

function KingKong() {

  return (
    <div style={{ marginTop: '10px', textAlign: 'center' }}>
      <Grid columns={2} gap={8}>
        <Grid.Item>
          <Button>新品上架</Button>
        </Grid.Item>
        <Grid.Item>
          <Button>热门出租</Button>
        </Grid.Item>
      </Grid>
    </div>
  )
}

function Product() {

  const [data, setData] = useState([])
  const [hasMore, setHasMore] = useState(true)
  async function loadMore() {
    // const append = await mockRequest()
    // setData(val => [...val, ...append])
    // setHasMore(append.length > 0)
  }
  const demoSrc =
    'https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=60';
  const labels = [
    {
      id: 1,
      name: '新品上架',
      color: 'red'
    },
    {
      id: 2,
      name: '降价处理',
      color: '#2db7f5'
    },
    {
      id: 3,
      name: '热租',
      color: 'primary'
    }
  ]
  return (
    <div>
      <Grid columns={2} gap={8}>
        <Grid.Item>
          <ProductListCard key={1} id={10} name='汪汪队立大功官方授权商品，孩子们最喜欢' src={demoSrc} labels={labels}
            price={102.56} dayPrice={56.32} />
        </Grid.Item>
        <Grid.Item>
          <ProductListCard key={2} id={10} name='汪汪队立大功官方授权商品，孩子们最喜欢' src={demoSrc} labels={labels} price={102.56} dayPrice={56.32} />
        </Grid.Item>
        <Grid.Item>
          <ProductListCard key={3} id={10} name='汪汪队立大功官方授权商品，孩子们最喜欢' src={demoSrc} price={102.56} dayPrice={56.32} />
        </Grid.Item>
        <Grid.Item>
          <ProductListCard key={4} id={10} name='汪汪队立大功官方授权商品，孩子们最喜欢' src={demoSrc} price={102.56} dayPrice={56.32} />
        </Grid.Item>
        <Grid.Item>
          <ProductListCard key={5} id={10} name='汪汪队立大功官方授权商品，孩子们最喜欢' src={demoSrc} price={102.56} dayPrice={56.32} />
        </Grid.Item>
        <Grid.Item>
          <ProductListCard key={6} id={10} name='汪汪队立大功官方授权商品，孩子们最喜欢' src={demoSrc} price={102.56} dayPrice={56.32} />
        </Grid.Item>
        <Grid.Item>
          <ProductListCard key={7} id={10} name='汪汪队立大功官方授权商品，孩子们最喜欢' src={demoSrc} price={102.56} dayPrice={56.32} />
        </Grid.Item>
        <Grid.Item>
          <ProductListCard key={8} id={10} name='汪汪队立大功官方授权商品，孩子们最喜欢' src={demoSrc} price={102.56} dayPrice={56.32} />
        </Grid.Item>
      </Grid>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </div>
  )
}
export default RentalHome;