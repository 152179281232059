
import { Button, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'


const RentalMe = () => {

  const navigate = useNavigate()
  const handClick = () => {
    Toast.show({
      content: '跳转成功',
      position: 'bottom',
    })
    navigate("/")
  }
  return (
    <div>
      我的
      <Button onClick={handClick} >首页</Button>
    </div>
  )
}

export default RentalMe;