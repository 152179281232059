import { NavBar } from 'antd-mobile'

const RentalBack = ({ title, left, right, handBack }) => {
  return (
    <NavBar style={{
      '--height': '6vh',
      '--border-bottom': '1px #eee solid',
    }} onBack={handBack} left={left} right={right}>
      {title}
    </NavBar>
  )
}

export default RentalBack